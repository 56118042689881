import * as THREE from "three";
import Experience from "../Experience.js";
import GSAP from "gsap";

const LOCATIONS_MAX = 4;

export default class Controls {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.sizes = this.experience.sizes;
        this.resources = this.experience.resources;
        this.time = this.experience.time;
        this.camera = this.experience.camera;
        this.church = this.experience.world.church;

        this.progress = 0;
        this.dummyCurve = new THREE.Vector3(0, 0, 0);

        this.lerp = {
            current: 0,
            target: 0,
            ease: 0.03
        };

        this.position = new THREE.Vector3(0, 0, 0);
        this.lookAtPosition = new THREE.Vector3(0, 0, 0);

        this.page_locations = [0, 0.31, 0.69, 0.87, 0.95];

        this.page_index = 0;

        this.isScrolling = false;
        this.activeLinkRunning = false;


        this.setPath();
        this.onWheel();
        this.setNavigation();
        this.page_elements = [
            document.querySelectorAll(".page1"),
            document.querySelectorAll(".page2"),
            document.querySelectorAll(".page3"),
            document.querySelectorAll(".page4"),
            document.querySelectorAll(".page5"),
        ]
    }

    setPath() {
        this.curve = new THREE.CatmullRomCurve3([
            new THREE.Vector3(0, 1, 10.25), //begin *
            new THREE.Vector3(0, 3, 5), //up
            new THREE.Vector3(0, 1, 3.5), //enter_room1
            new THREE.Vector3(-1, 1, 2.5), //cross *
            new THREE.Vector3(2, 2, -1), //up_room1
            new THREE.Vector3(6, 1, -2), //outside tilt *
            new THREE.Vector3(2, 2, -4), //enter_room2
            new THREE.Vector3(0, 2, -4), //enter_room2 *
            new THREE.Vector3(0, 2, -6), //face_books *
        ]);

        //x, z, y (forward is posiitve)
        this.lookCurve = new THREE.CatmullRomCurve3([
            new THREE.Vector3(0, 1, 8), //begin *
            new THREE.Vector3(0, 3, 5), //up
            new THREE.Vector3(-3, 1, 1.5), //enter_room1
            new THREE.Vector3(2, 2, -1), //cross *
            new THREE.Vector3(4, 1.5, -1.5), //up_room1
            new THREE.Vector3(5, 1, -2.5), //outside tilt *
            new THREE.Vector3(2, 2, -4), //enter_room2
            new THREE.Vector3(0, 1.7, -4), //enter_room2 *
            new THREE.Vector3(0.007, 2.4, -6), //face_books *
        ]);
    }

    async scrollNext(target_index) {        
        
        return new Promise((resolve) => {
            GSAP.to(this.lerp, {
                duration: 2,
                // duration: 2 * Math.abs(target_index - this.page_index),
                target: this.page_locations[target_index], // Update the property you want to animate
                onUpdate: () => {
                    this.update();
                },
                onComplete: () => {
                    this.page_index = target_index;
                    this.page_elements[target_index].forEach((item) => {
                        item.classList.remove('invisible');
                        item.classList.add('visible');
                    })
                    resolve();
                },

            });
        });
    }

    async activeLink(index) {
        if (this.activeLinkRunning) {
            return;
        }
        this.activeLinkRunning = true;

        if (index === this.page_index) {
            this.activeLinkRunning = false;
            return;
        }

        this.navigation_btns.forEach((item) => {
            item.classList.remove('active')
        });

        this.page_elements[this.page_index].forEach((item) => {
            item.classList.remove('visible');
            item.classList.add('invisible');
        })
        this.navigation_btns[index].classList.add('active');
        await this.scrollNext(index);
        this.activeLinkRunning = false;
    }

    setNavigation() {
        const ulElement = document.querySelector('.navbar');
        this.navigation_btns = [];
        if (ulElement) {
            this.navigation_btns = ulElement.querySelectorAll('li');
            for (let i = 0; i < this.navigation_btns.length; ++i) {
                this.navigation_btns[i].addEventListener('click', () => {
                    this.activeLink(i);
                });
            }
        }
    }


    onWheel() {
        const handleWheel = async (e) => {
            window.removeEventListener("wheel", handleWheel);

            if (e.deltaY > 0 && this.page_index < LOCATIONS_MAX) {
                await this.activeLink(this.page_index + 1);
            } else if (e.deltaY < 0 && this.page_index > 0) {
                await this.activeLink(this.page_index - 1);
            }

            window.addEventListener("wheel", handleWheel);
        };

        window.addEventListener("wheel", handleWheel);
    }

    update() {

        this.lerp.target = GSAP.utils.clamp(0, 1, this.lerp.target);
        this.lerp.current = GSAP.utils.clamp(0, 1, this.lerp.current);

        this.lerp.current = GSAP.utils.interpolate(
            this.lerp.current,
            this.lerp.target,
            this.lerp.ease
        );

        this.curve.getPointAt(this.lerp.current, this.position);

        this.lookCurve.getPointAt(this.lerp.current + 0.00001, this.lookAtPosition);

        this.camera.perspectiveCamera.position.copy(this.position);
        this.camera.perspectiveCamera.lookAt(this.lookAtPosition);
    }
}
