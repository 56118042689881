import "./style.scss";
import Experience from "./Experience/Experience.js";

// const iframe = document.getElementById('map');

// fetch('/.netlify/functions/google_api')
//     .then((response) => response.json())
//     .then((data) => {
//         console.log(data);
//         iframe.src = data.mapEmbedUrl;
//     })
//     .catch((error) => {
//         console.error(error);
//     });

// const expandedText = document.querySelector('.expand-text');
// const expandHeader = expandedText.querySelector('h2');
// const expandText = expandedText.querySelector('p');
// const expandClose = expandedText.querySelector('.close');
    

// function openExpandText(e) {
//     const header = e.currentTarget.getElementsByTagName('h2')[0].innerText;
//     const innerText = e.currentTarget.getElementsByTagName('p')[0].innerText;
    
//     expandHeader.innerText = header;
//     expandText.innerText = innerText;
//     expandedText.classList.add('open');
// }

// function expandOverlayClose() {
//     expandedText.classList.remove('open');
// }

// const expandBtns = document.querySelectorAll('.overlayBtn');
// expandBtns.forEach(btn => btn.addEventListener('click', openExpandText));

// expandClose.addEventListener('click', expandOverlayClose);

function openPopup(popup) {
    popup.classList.add('open');
}

function closePopup(popup) {
    popup.classList.remove('open');
}

//The Credits Popup
const credits = document.querySelector('.credits');
const creditsClose = credits.querySelector('.close');
creditsClose.addEventListener('click', () => closePopup(credits));

const credit_btn_container = document.querySelector('.creditBtn');
const creditBtn = credit_btn_container.querySelector('.item__overlay');
creditBtn.addEventListener('click', () => openPopup(credits));


//The Pastor History Popup
const pastor_intro_ctd = document.querySelector('.pastorIntro');
const pastor_intro_close = pastor_intro_ctd.querySelector('.close');
pastor_intro_close.addEventListener('click', () => closePopup(pastor_intro_ctd));

const pastor_intro_btn_container = document.querySelector('.pastorIntroBtn');
const pastor_intro_btn = pastor_intro_btn_container.querySelector('.item__overlay');
pastor_intro_btn.addEventListener('click', () => openPopup(pastor_intro_ctd));


//The Pastor History Popup
const church_history_ctd = document.querySelector('.churchHistory');
const church_history_close = church_history_ctd.querySelector('.close');
church_history_close.addEventListener('click', () => closePopup(church_history_ctd));

const church_history_btn_container = document.querySelector('.churchHistoryBtn');
const church_history_btn = church_history_btn_container.querySelector('.item__overlay');
church_history_btn.addEventListener('click', () => openPopup(church_history_ctd));




// const experience = new Experience(document.querySelector(".experience-canvas"));

const experience = new Experience(document.querySelector('canvas.webgl'))