import * as THREE from "three";

import { EventEmitter } from "events";

export default class Time extends EventEmitter {
    constructor() {
        super();
        this.clock = new THREE.Clock()

        this.update();
    }

    update() {
        this.emit("update");
        window.requestAnimationFrame(() => this.update());
    }
}
