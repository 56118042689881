export default [
    {
        name: "church",
        type: "glbModel",
        path: "church.glb",
    },
    {
        name: "body",
        type: "jpg",
        path: "body.jpg",
    },
    {
        name: "foliage",
        type: "jpg",
        path: "foliage.jpg",
    },
];
