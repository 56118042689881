import * as THREE from "three";
import Experience from "../Experience.js";
import GSAP from "gsap";

export default class Church {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this.time = this.experience.time;


        this.church = this.resources.items.church.scene;

        this.bodyTexture = this.resources.items.body;
        this.bodyTexture.flipY = false
        this.bodyTexture.colorSpace = THREE.SRGBColorSpace;

        this.foliageTexture = this.resources.items.foliage;
        this.foliageTexture.flipY = false
        this.foliageTexture.colorSpace = THREE.SRGBColorSpace;

        this.bodyMaterial = new THREE.MeshBasicMaterial({ map: this.bodyTexture });
        this.foliageMaterial = new THREE.MeshBasicMaterial({ map: this.foliageTexture });

        this.lerp = {
            current: 0,
            target: 0,
            ease: 0.1,
        };

        this.setModel();
    }

    setModel() {

        const bodyMesh = this.church.children.find(child => child.name === 'Body');
        const foliageMesh = this.church.children.find(child => child.name === 'Foliage');

        bodyMesh.material = this.bodyMaterial;
        foliageMesh.material = this.foliageMaterial;
        this.scene.add(this.church);
        this.church.rotation.y = Math.PI / 2;
        this.church.scale.set(0.3, 0.3, 0.3);
    }

    update() {
        this.lerp.current = GSAP.utils.interpolate(
            this.lerp.current,
            this.lerp.target,
            this.lerp.ease
        );
    }
}
