import { EventEmitter } from "events";
import Experience from "./Experience.js";
import GSAP from "gsap";

export default class Preloader extends EventEmitter {
    constructor() {
        super();
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.sizes = this.experience.sizes;
        this.resources = this.experience.resources;
        this.camera = this.experience.camera;
        this.world = this.experience.world;
        this.device = this.sizes.device;

        this.world.on("worldready", () => {
            this.playIntro();
        });
    }

    async playIntro() {
        await this.loading();
        this.emit("enablecontrols");
    }

    loading() {
        return new Promise((resolve) => {
            this.timeline = new GSAP.timeline();
            this.timeline.to(".preload", {
                delay: 1,
                onComplete: () => {
                    document
                        .querySelector(".fullscreen")
                        .classList.add("invisible");
                    resolve();
                },
            });
        });
        
        
    }


    update() {
    }
}
