import * as THREE from "three";
import Experience from "./Experience.js";

export default class Camera {
    constructor() {
        this.experience = new Experience();
        this.sizes = this.experience.sizes;
        this.scene = this.experience.scene;
        this.canvas = this.experience.canvas;

        this.createCamera();
    }

    createCamera() {
        this.perspectiveCamera = new THREE.PerspectiveCamera(
            55,
            this.sizes.aspect,
            0.1,
            50
        );
        this.perspectiveCamera.position.x = 12
        this.perspectiveCamera.position.y = 14
        this.perspectiveCamera.position.z = 20

        this.scene.add(this.perspectiveCamera);
    }

    createOrthographicCamera() {
        this.perspectiveCamera = new THREE.OrthographicCamera(
            (-this.sizes.aspect * this.sizes.frustrum) / 2,
            (this.sizes.aspect * this.sizes.frustrum) / 2,
            this.sizes.frustrum / 2,
            -this.sizes.frustrum / 2,
            -50,
            50
        );

        // 6.5
        this.perspectiveCamera.position.y = 5.65;
        this.perspectiveCamera.position.z = 10;
        this.perspectiveCamera.rotation.x = -Math.PI / 6;

        this.scene.add(this.perspectiveCamera);

        // this.helper = new THREE.CameraHelper(this.orthographicCamera);
        // this.scene.add(this.helper);

        // const size = 20;
        // const divisions = 20;

        // const gridHelper = new THREE.GridHelper(size, divisions);
        // this.scene.add(gridHelper);

        // const axesHelper = new THREE.AxesHelper(10);
        // this.scene.add(axesHelper);
    }

    resize() {
        // this.perspectiveCamera.fov = Math.max((-0.05) * (this.sizes.width) + 151, (-0.05) * (this.sizes.height) + 109);
        this.perspectiveCamera.aspect = this.sizes.aspect;

        this.perspectiveCamera.updateProjectionMatrix();

        // this.perspectiveCamera.left =
        //     (-this.sizes.aspect * this.sizes.frustrum) / 2;
        // this.perspectiveCamera.right =
        //     (this.sizes.aspect * this.sizes.frustrum) / 2;
        // this.perspectiveCamera.top = this.sizes.frustrum / 2;
        // this.perspectiveCamera.bottom = -this.sizes.frustrum / 2;
        // this.perspectiveCamera.updateProjectionMatrix();
    }

    update() {
    }
}
